import React, {Component} from 'react'
import {navigate} from 'gatsby';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {storeCurrentRequestId} from '../../redux/actions';

export class EditFormButton extends Component {
  editForm() {
    const {form} = this.props.ownProps
    this
      .props
      .storeCurrentRequestId(form);
    navigate(`${form.department}/${form.workTypeSelected}`);
  }
  render() {
    return (
      <button
        className="text-blue-400 hover:text-blue-500 text-sm underline"
        onClick={this
        .editForm
        .bind(this)}>
        Continue
      </button>
    )
  }
}

const mapStateToProps = (state, props) => ({current_request_id: state.current_request_id, ownProps: props})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFormButton);