import React, {Component} from 'react'

export default class jobStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusClass: ''
    }
  }
  componentDidMount() {
    this.setStatusClass();
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      this.setStatusClass();
    }
  }

  setStatusClass() {
    const {status} = this.props;
    if (status === 'Approved') {
      this.setState({
        statusClass: 'bg-lime-300 text-green-900 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs te' +
            'xt-center'
      })
    }
    if (status === 'Complete') {
      this.setState({
        statusClass: 'bg-green-200 text-green-800 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs te' +
            'xt-center'
      })
    }
    if (status === 'Waiting') {
      this.setState({
        statusClass: 'bg-yellow-400 text-orange-800 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs ' +
            'text-center'
      })
    }
    if (status === 'Rejected') {
      this.setState({
        statusClass: 'bg-red-400 text-red-900 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs text-c' +
            'enter'
      })
    }
    if (status === 'In Progress') {
      this.setState({
        statusClass: 'bg-blue-300 text-blue-900 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs text' +
            '-center'
      })
    }
    if (status === 'Awaiting Approval') {
      this.setState({
        statusClass: 'bg-indigo-300 text-indigo-900 px-3 py-1 inline-block w-full rounded-lg mx-auto text-xs ' +
            'text-center'
      })
    }
  }

  render() {
    return (
      <div className="w-full">
        <div className={this.state.statusClass}>
          {this.props.status}
        </div>
      </div>
    )
  }
}
