import React, { Component } from "react"
import EditFormButton from "./EditFormButton"
import JobStatus from "./JobStatus"
import DeleteFormButton from "./DeleteFormButton"
export default class RequestHistory extends Component {
  render() {
    const sortedHistory = this.props.history.sort().reverse()
    const history = sortedHistory.map((item, key) => (
      <tr
        key={key}
        className="text-brand-blue odd:bg-blue-50 border-b odd:border-blue-100 font-medium"
      >
        <td className="text-sm px-2 py-2">
          {new Date(item.created).toDateString()}
        </td>
        {/* <td className="text-sm px-2 py-2 hidden md:table-cell capitalize">{item.department}</td> */}
        <td className="text-sm px-2 py-2 uppercase">{item.workTypeSelected}</td>
        <td className="text-sm px-2 py-2 hidden md:table-cell">
          {new Date(item.updated).toDateString()}
        </td>
        <td className="text-sm px-2 py-2">
          <JobStatus status={item.status} />
        </td>
        <td className="px-2 py-2">
          {item.status === "In Progress" || item.status === "Waiting" ? (
            <EditFormButton form={item} />
          ) : null}
        </td>
        <td className="px-2 py-2">
          {item.status === "In Progress" ? (
            <DeleteFormButton form={item} />
          ) : null}
        </td>
      </tr>
    ))
    const { loading } = this.props
    const noHistory = [...this.props.history].length < 1
    return (
      <div className={`${"text-gray-700 container"} ${"history"}`}>
        <div>
          {loading ? (
            <p className="font-book text-center text-4xl text-brand-blue mr-auto my-8">
              Loading...
            </p>
          ) : (
            <p className="font-book text-center text-4xl text-brand-blue mr-auto my-8">
              Request History
            </p>
          )}
          <div className="mt-2 bg-white block w-full rounded-lg pt-4 border transition transition-all duration-1000 shadow-lg">
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <table
                className="w-full text-left text-brand-blue"
                id="request-history-table"
              >
                <tbody>
                  {noHistory ? null : (
                    <tr>
                      <th className="px-2 py-3 border-b text-sm">Created At</th>
                      {/* <th className="px-2 py-3 border-b text-sm hidden md:table-cell">Department</th> */}
                      <th className="px-2 py-3 border-b text-sm">Work</th>
                      <th className="px-2 py-3 border-b text-sm hidden md:table-cell">
                        Last Updated
                      </th>
                      <th className="px-2 py-3 border-b text-sm">Status</th>
                      <th>
                        <span className="hidden">control</span>
                      </th>
                      <th>
                        <span className="hidden">remove</span>
                      </th>
                    </tr>
                  )}
                  {history}
                </tbody>
              </table>
            )}
            {noHistory && (
              <div className="my-20 text-center">
                <p className="text-2xl">No previous requests found</p>
                <p>Any requests you make will show up here</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export class LoadingPlaceholder extends Component {
  render() {
    const loadingArray = [1, 1, 1, 1, 1, 1]
    const placeholders = loadingArray.map((item, key) => (
      <tr className=" odd:bg-gray-100 border-b">
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
        <td className="px-2 py-2">
          <span className="form-field-animation bg-gray-200 my-1 h-3 w-full inline-block rounded-lg"></span>
        </td>
      </tr>
    ))
    return (
      <table className="w-full text-left text-sm opacity-50">
        <tbody>
          <tr>
            <th className="px-2 py-3 border-b">
              <span className="form-field-animation w-full bg-gray-300 h-3 inline-block rounded-lg"></span>
            </th>
            <th className="px-2 py-3 border-b">
              <span className="form-field-animation w-full bg-gray-300 h-3 inline-block rounded-lg"></span>
            </th>
            <th className="px-2 py-3 border-b">
              <span className="form-field-animation w-full bg-gray-300 h-3 inline-block rounded-lg"></span>
            </th>
            <th className="px-2 py-3 border-b">
              <span className="form-field-animation w-full bg-gray-300 h-3 inline-block rounded-lg"></span>
            </th>
            <th className="px-2 py-3 border-b">
              <span className="form-field-animation w-full bg-gray-300 h-3 inline-block rounded-lg"></span>
            </th>
            <th>
              <span className="hidden">control</span>
            </th>
          </tr>
          {placeholders}
        </tbody>
      </table>
    )
  }
}
