import React, { Component } from "react"
import "../../content/icons/dashboard/flaticon.css"
import { Link } from "gatsby"
export default class DashboardTiles extends Component {
  render() {
    return (
      <div className="flex flex-wrap items-stretch text-center text-white container mx-auto">
        <Tile
          className="promotion"
          title="Promotion pre-approval request"
          url="/product-promotion"
          id="create-promotion-request"
          icon="dashicon-shopping-online"
          transitionDelay={300}
          color="yellow"
          rgb="27,54,101"
        />
        <Tile
          className="design"
          title="design"
          url="/design"
          id="create-design-request"
          icon="dashicon-browser"
          transitionDelay={100}
          color="green"
          rgb="0,148,201"
        />
        <Tile
          className="documents"
          title="documents"
          url="/documents"
          id="create-document-request"
          icon="dashicon-document"
          transitionDelay={200}
          color="green"
          rgb="71, 120, 116"
        />

        <Tile
          className="social"
          title="social media"
          url="/social-media"
          id="create-social-media-request"
          icon="dashicon-promotion"
          transitionDelay={500}
          color="indigo"
          rgb="148,187,32"
        />
        <Tile
          className="video"
          title="video"
          url="/video"
          id="create-video-request"
          icon="dashicon-video-1"
          transitionDelay={700}
          color="pink"
          rgb="236,28,82"
        />
        <Tile
          className="websites"
          title="websites"
          url="/web"
          id="create-web-request"
          icon="dashicon-browser"
          transitionDelay={1000}
          color="purple"
          rgb="241,91,41"
        />
      </div>
    )
  }
}

export class Tile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transitioned: false,
      hover: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transitioned: true })
    }, this.props.transitionDelay / 4)
    this.setState({ hover: false })
  }
  handleHoverTile() {
    const status = this.state.hover
    this.setState({ hover: !status })
  }

  render() {
    const { transitioned, hover } = this.state
    const { title, color, icon, url, rgb, id, className } = this.props
    const introtransitionStyle = `block py-4 md:py-10 lg:py-10 hover:bg-${color}-600 cursor-pointer rounded-md focus:shadow-sm hover:shadow-lg transition duration-500 transition-all opacity-0`
    const defaultStyle = `self stretch block py-4 md:py-10 lg:py-10 hover:bg-${color}-600 cursor-pointer rounded-md hover:rounded-lg focus:shadow-sm hover:shadow-lg transition duration-500 transition-all opacity-1 shadow-xl hover:shadow-sm`
    const style = {
      background: `rgba(${rgb}, 0.8)`,
      border: `2px solid rgba(${rgb}, 0.5)`,
    }
    const hoverStyle = {
      background: `rgba(${rgb}, 1)`,
      border: `2px solid rgba(${rgb}, 0.8)`,
      color: `rgba(255,255,255,0.9)`,
    }

    return (
      <div
        className={`${"w-6/12 md:w-6/12 lg:w-4/12 p-2 dashboard-tile"} ${className}`}
      >
        <Link
          onMouseEnter={this.handleHoverTile.bind(this)}
          onMouseLeave={this.handleHoverTile.bind(this)}
          className={transitioned ? defaultStyle : introtransitionStyle}
          style={hover ? hoverStyle : style}
          to={url}
          id={id}
        >
          <div className="lg:my-10 my-2">
            <p>
              <span className={`text-6xl ${icon} dash-icon`}></span>
            </p>
            <h4 className="mt-4 font-bold text-1xl uppercase">{title}</h4>
          </div>
        </Link>
      </div>
    )
  }
}
