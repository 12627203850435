import React, { Component } from "react"
import { navigate } from "gatsby"
import auth from "../utils/auth"
import Layout from "../components/layout"
import "react-datepicker/dist/react-datepicker.css"
import WithAuthentication from "../context/WithAuthentication"
import axios from "axios"
import DashboardTiles from "../components/dashboard/DashboardTiles"
import RequestHistory from "../components/dashboard/RequestHistory"
import "intro.js/introjs.css"
import { Steps } from "intro.js-react"
import IntroWrapper from '../components/client-only-wrapper';

const API = process.env.API
export class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      department: "",
      requestType: "",
      workTypeSelected: "",
      departmentSelectActive: false,
      request_history: [],
      windowHeight: "",
      loading: true,
      options: {
        showBullets: false,
        showProgress: true,
        dontShowAgain: true,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: ".promotion",
          title: "Pre-Approval Form",
          intro:
            "<strong>1ST STEP:</strong> click here to fill out a request form. Once approved you will be able to request a flyer for that product promotion.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".design",
          title: "Design",
          intro:
            "Want a flyer for a event/royalty/etc? Click here to start the process",
          tooltipClass: "intro-title",
        },
        {
          element: ".documents",
          title: "Documents",
          intro:
            "Each document request can be unique, please be specific when using this form.",
          position: "right",
          tooltipClass: "intro-title",
        },
        {
          element: ".social",
          title: "Social Media",
          intro:
            "Fill out this form so we know which platforms and what content to include on your social media campaign.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".video",
          title: "Video",
          intro:
            "Want a video created for an event or a product? Click here to start the process",
          tooltipClass: "intro-title",
        },
        {
          element: ".websites",
          title: "Website Request",
          intro:
            "Want a change to your website? Click here to start the process",
          position: "right",
          tooltipClass: "intro-title",
        },
        {
          element: ".history",
          title: "Request History",
          intro:
            "Once you have made the request. Make sure that you have submitted it and not just saved it. It wil pop up here if it has been submitted",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }

  checkScreenHeight() {
    this.setState({ windowHeight: window.innerHeight })
  }
  componentDidMount() {
    window.addEventListener("resize", this.checkScreenHeight.bind(this))
    const token = auth.getToken()
    const userInfo = auth.getUserInfo()
    if (!token) {
      navigate("/auth")
    } else {
      axios({
        method: "get",
        url: `${API}/users/${userInfo.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          const request_history = res.data.request_history
          if (res.data.request_history) {
            this.setState({ request_history, loading: false })
          } else {
            this.setState({ request_history: [], loading: false })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({ loading: false })
        })
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", null)
  }

  clearDepartment() {
    this.setState({ department: "" })
  }
  render() {
    const {
      windowHeight,
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    return (
      <>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>
        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>

        <Layout>
          <div
            className="py-10 lg:items-center min-h-screen"
            style={{
              minHeight: windowHeight ? `${windowHeight}px` : null,
            }}
          >
            <div className="w-full">
              <DashboardTiles />
              <div className="container mx-auto w-full">
                <div className="request-history">
                  <RequestHistory
                    history={this.state.request_history}
                    loading={this.state.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default WithAuthentication(LandingPage)
